<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="mb-8 justify-space-between">
          Статистика по менеджерам
        </v-card-title>
        <v-card-actions>
          <YearMonth v-model="date" @change="loadTable(); getFinance(date[0], date[1])"/>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="rows"
              :headers="headers"
              item-key="id"
              :search="search"
              sort-by="isp.total.sum"
              sort-desc
              :loading="loading"
              :footer-props="{'items-per-page-options':[10]}"
              no-data-text="Данных нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr v-if="+item.manager.okk">
                <td style="white-space: nowrap; cursor: pointer" @click="openManager(item.manager.n_id)">
                  <div>{{ item.manager.login }} {{ +item.manager.okk ? '(ОКК)' : '' }}</div>
                </td>
                <td>
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    Исполняется:
                    <OrdersSum :sum="item.isp.total.sum"
                               :count="item.isp.total.count" :with-av="true"
                               :class="{'mt-auto': salaryBonusesEnabled}"/>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusKPI_OKK(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_kpi_okk)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus mt-auto pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        KPI > {{ $root.printCost(500000) }}
                      </div>
                    </v-tooltip>

                    Продажи с обзвона:
                    <OrdersSum :sum="item.from_feedback ? item.from_feedback.sum : 0"
                               :count="item.from_feedback ? item.from_feedback.count : 0"
                               :class="{'mt-auto': salaryBonusesEnabled}"/>
                    ({{
                      +item.isp.total.sum > 0 ?
                          parseInt((item.from_feedback ? item.from_feedback.sum : 0) * 100 / item.isp.total.sum) + '%' : '0%'
                    }})

                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isSalesOKK(item)" v-bind="attrs" v-on="on" class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.sales_okk)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus mt-auto pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Продажи с обзвона качества минимум 10% за месяц
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    <OrdersSum :sum="item.soc_networks ? item.soc_networks.sum : 0"
                               :count="item.soc_networks ? item.soc_networks.count : 0" :with-av="true"
                               :class="{'mt-auto': salaryBonusesEnabled}"/>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusSocialNetworks(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.soc_networks_okk)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus mt-auto pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Социальные сети:
                        <ul>
                          <li>Оформленных заказов > {{ $root.printCost('750000') }}</li>
                          <li>Средний чек > {{ $root.printCost('5000') }}</li>
                          <li>Удаленных заказов не более 40%</li>
                        </ul>
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    <div :class="{'mt-auto': salaryBonusesEnabled}">
                      {{ item.manager.conflict_orders.length }}&nbsp;шт.
                    </div>
                    <v-row>
                      Бонусы и переуборки:&nbsp;{{calcConflictBonuses(item.manager.conflict_orders)[0]}} ({{calcConflictBonuses(item.manager.conflict_orders)[1]}}%)
                    </v-row>
                    <v-row>
                      Решено деньгами:&nbsp;{{ calcConflictMoney(item.manager.conflict_orders)[0] }} ({{calcConflictMoney(item.manager.conflict_orders)[1]}}%)
                    </v-row>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isSatisfiedClients(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_satisfied_okk)}` }}
                        </div>
                        <div v-else class="salary-no-bonus mt-auto pb-4" v-bind="attrs" v-on="on">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Более 80% негативных клиентов удовлетворено бонусами и скидками, менее 20% урегулировано
                        деньгами
                      </div>
                    </v-tooltip>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isResolveDuration(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.resolve_duration_okk)}` }}
                        </div>
                        <div v-else class="salary-no-bonus mt-auto pb-4" v-bind="attrs" v-on="on">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Срок урегулирования конфликтных ситуаций до 7 дней
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div v-if="item.manager.late_sessions.length"
                             class="text-center">
                          {{ item.manager.late_sessions.length }}
                          {{ $root.numberWord(item.manager.late_sessions.length, ['смена', 'смены', 'смен']) }}
                        </div>
                        <div v-else style="color: green">нет</div>
                      </div>
                    </template>
                    <div style="max-width: 200px;">
                      <ul>
                        <li>Опоздание более 2-х раз (более 10 минут) = -{{ $root.printCost('5000') }}</li>
                        <li>Опоздание более 3-х раз (более 10 минут) = увольнение</li>
                      </ul>
                    </div>
                  </v-tooltip>
                  <ul>
                    <li v-for="(session, i) in item.manager.late_sessions" :key="i" style="white-space: nowrap">
                      {{ $moment(session.from.date).format('DD MMM') }} {{ session.from.time }}
                    </li>
                  </ul>
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div v-if="item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length"
                             class="text-center">
                          {{ item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length }}
                          {{
                            $root.numberWord(item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length,
                                ['смена', 'смены', 'смен'])
                          }}
                        </div>
                        <div v-else style="color: green">нет</div>
                      </div>
                    </template>
                    <div>
                      5 смен, где >10% пропущенных вызовов = -{{ $root.printCost('2000') }}
                    </div>
                  </v-tooltip>
                  <ul>
                    <li v-for="(call, i) in item.manager.missed_calls.filter(_call=>(_call.missed * 100) / _call.total >=10)"
                        :key="i" style="white-space: nowrap">
                      {{ $moment(call.date).format('DD MMM') }} <span style="color: red">{{ call.missed }}</span> /
                      {{ call.total }}
                    </li>
                  </ul>
                </td>
                <td>
                  <template v-if="salaryBonusesEnabled">{{ $root.printCost(calcManagerSalary(item)) }}</template>
                  <template v-else>---</template>
                </td>
              </tr>
              <tr v-else>
                <td style="white-space: nowrap">
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    <div :class="{'mt-auto': salaryBonusesEnabled}">{{ item.manager.login }}</div>
                    <Rate v-if="item.manager.rate" small :value="item.manager.rate"/>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusRate(item)" v-bind="attrs" v-on="on" class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_rate)}` }}
                        </div>
                        <div v-else class="salary-no-bonus mt-auto pb-4" v-bind="attrs" v-on="on">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Рейтинг от клиентов > {{ bonusInfo.rate }}
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td style="cursor: pointer" @click="openManager(item.manager.n_id)">
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    Оформлено:
                    <OrdersSum :sum="item.total.sum"
                               :count="item.total.count" :with-av="true"
                    />
                    <template v-if="item.isp">
                    Исполняется:
                    <OrdersSum :sum="item.isp.total.sum"
                               :count="item.isp.total.count" :with-av="true"
                               />
                    </template>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusAvgCheck(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_average_bill)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Самый высокий средний чек за месяц среди всех менеджеров (> {{
                          $root.printCost(bonusInfo.avrgBill)
                        }})
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <div class="flex-column d-flex justify-center" style="height: 100%" v-if="item.isp">
                    <v-row :class="{'mt-auto': salaryBonusesEnabled}">
                      <v-col>
                        <span class="d-inline-block cleantype-span">Рег.</span>
                      </v-col>
                      <v-col>
                        <OrdersSum v-if="item.isp.standart" :sum="item.isp.standart.sum" :count="item.isp.standart.count" :with-av="true"/>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col>
                        <span class="d-inline-block cleantype-span">Ген.</span>
                      </v-col>
                      <v-col>
                        <OrdersSum v-if="item.isp.general" :sum="item.isp.general.sum" :count="item.isp.general.count" :with-av="true"/>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col>
                        <span class="d-inline-block cleantype-span">Ремонт</span>
                      </v-col>
                      <v-col>
                        <OrdersSum v-if="item.isp.remont" :sum="item.isp.remont.sum" :count="item.isp.remont.count" :with-av="true"/>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col>
                        <span class="d-inline-block cleantype-span">Другое</span>
                      </v-col>
                      <v-col>
                        <OrdersSum v-if="item.isp.other" :sum="item.isp.other.sum" :count="item.isp.other.count" :with-av="true"/>
                      </v-col>
                    </v-row>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusAvgCheckCleanings(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_average_bill_cleanings)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus mt-auto pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Средний чек не ниже:
                        <ul>
                          <li>быстрая - {{ $root.printCost(bonusInfo.avgBillStandart) }}</li>
                          <li>генеральная - {{ $root.printCost(bonusInfo.avgBillGeneral) }}</li>
                          <li>После ремонта - {{ $root.printCost(bonusInfo.avgBillRemont) }}</li>
                        </ul>
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <div class="flex-column d-flex justify-center" style="height: 100%">
                    <OrdersSum style="color:red;" :sum="item.canceled.sum" :count="item.canceled.count"
                               :class="{'mt-auto': salaryBonusesEnabled}"/>
                    <span class="cleantype-span d-block">
                  {{ parseInt(100 * item.canceled.sum / (item.total.sum + item.canceled.sum)) }}% от всех
                  </span>
                    <v-tooltip top v-if="salaryBonusesEnabled">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="isBonusDeletedOrders(item)" v-bind="attrs" v-on="on"
                             class="salary-bonus-value mt-auto pb-4">
                          {{ `+ ${$root.printCost(salaryData.bonus_deleted_orders)}` }}
                        </div>
                        <div v-else v-bind="attrs" v-on="on" class="salary-no-bonus mt-auto pb-4">Без бонуса</div>
                      </template>
                      <div style="max-width: 200px;">
                        Удаленных заказов не больше {{ bonusInfo.deleted_orders }}%
                      </div>
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div v-if="item.manager.late_sessions.length"
                             class="text-center">
                          {{ item.manager.late_sessions.length }}
                          {{ $root.numberWord(item.manager.late_sessions.length, ['смена', 'смены', 'смен']) }}
                        </div>
                        <div v-else style="color: green">нет</div>
                      </div>
                    </template>
                    <div style="max-width: 200px;">
                      <ul>
                        <li>Опоздание более 2-х раз (более 10 минут) = -{{ $root.printCost('5000') }}</li>
                        <li>Опоздание более 3-х раз (более 10 минут) = увольнение</li>
                      </ul>
                    </div>
                  </v-tooltip>
                  <ul>
                    <li v-for="(session, i) in item.manager.late_sessions" :key="i" style="white-space: nowrap">
                      {{ $moment(session.from.date).format('DD MMM') }} {{ session.from.time }}
                    </li>
                  </ul>
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div v-if="item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length"
                             class="text-center">
                          {{ item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length }}
                          {{
                            $root.numberWord(item.manager.missed_calls.filter(call => (call.missed * 100) / call.total >= 10).length,
                                ['смена', 'смены', 'смен'])
                          }}
                        </div>
                        <div v-else style="color: green">нет</div>
                      </div>
                    </template>
                    <div>
                      5 смен, где >10% пропущенных вызовов = -{{ $root.printCost('2000') }}
                    </div>
                  </v-tooltip>
                  <ul>
                    <li v-for="(call, i) in item.manager.missed_calls.filter(_call=>(_call.missed * 100) / _call.total >=10)"
                        :key="i" style="white-space: nowrap">
                      {{ $moment(call.date).format('DD MMM') }} <span style="color: red">{{ call.missed }}</span> /
                      {{ call.total }}
                    </li>
                  </ul>
                </td>
                <td>
                  <template v-if="salaryBonusesEnabled">{{ $root.printCost(calcManagerSalary(item)) }}</template>
                  <template v-else>---</template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <User ref="manager_modal"/>
  </v-app>
</template>

<script>
import OrdersSum from "../../components/OrdersSum";
import Rate from "../../components/Rate";
import YearMonth from "@/components/YearMonth";
import managerMixins from "@/mixins/managerMixins";
import User from "@/views/users/User";

export default {
  name: "Managers",
  components: {User, YearMonth, Rate, OrdersSum},
  mixins: [managerMixins],
  methods: {
    openManager(id) {
      this.$refs.manager_modal.user = {n_id: id};
      this.$refs.manager_modal.open();
    },
    calcConflictBonuses(orders) {
      return  [this.$root.printCost(orders
          .filter(order=>['bonus', 'reorder'].includes(order.feedback.resolve_type))
          .reduce((accumulator, currentValue) => accumulator + currentValue.feedback.conflict_sum*1, 0)),
        parseInt((orders
            .filter(order=>['bonus', 'reorder'].includes(order.feedback.resolve_type)).length / orders.length) * 100)
      ]
    },
    calcConflictMoney(orders) {
      return [
        this.$root.printCost(orders
            .filter(order=>['compensation', 'money_back'].includes(order.feedback.resolve_type))
            .reduce((accumulator, currentValue) => accumulator + currentValue.feedback.conflict_sum*1, 0)),
        parseInt((orders
            .filter(order=>['compensation', 'money_back'].includes(order.feedback.resolve_type)).length / orders.length) * 100)
      ]
    }
  }
}
</script>

<style scoped>
.cleantype-span {
  color: #8a93a2;
}

.salary-bonus-value {
  color: rgb(46, 184, 92);
  font-size: 12px;
  white-space: nowrap;
}

.salary-no-bonus {
  color: red;
  font-size: 12px;
}
</style>
