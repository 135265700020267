var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"mb-8 justify-space-between"},[_vm._v(" Статистика по менеджерам ")]),_c('v-card-actions',[_c('YearMonth',{on:{"change":function($event){_vm.loadTable(); _vm.getFinance(_vm.date[0], _vm.date[1])}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hover":"","items":_vm.rows,"headers":_vm.headers,"item-key":"id","search":_vm.search,"sort-by":"isp.total.sum","sort-desc":"","loading":_vm.loading,"footer-props":{'items-per-page-options':[10]},"no-data-text":"Данных нет","loading-text":"Загрузка...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(+item.manager.okk)?_c('tr',[_c('td',{staticStyle:{"white-space":"nowrap","cursor":"pointer"},on:{"click":function($event){return _vm.openManager(item.manager.n_id)}}},[_c('div',[_vm._v(_vm._s(item.manager.login)+" "+_vm._s(+item.manager.okk ? '(ОКК)' : ''))])]),_c('td',[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_vm._v(" Исполняется: "),_c('OrdersSum',{class:{'mt-auto': _vm.salaryBonusesEnabled},attrs:{"sum":item.isp.total.sum,"count":item.isp.total.count,"with-av":true}}),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusKPI_OKK(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_kpi_okk))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" KPI > "+_vm._s(_vm.$root.printCost(500000))+" ")])]):_vm._e(),_vm._v(" Продажи с обзвона: "),_c('OrdersSum',{class:{'mt-auto': _vm.salaryBonusesEnabled},attrs:{"sum":item.from_feedback ? item.from_feedback.sum : 0,"count":item.from_feedback ? item.from_feedback.count : 0}}),_vm._v(" ("+_vm._s(+item.isp.total.sum > 0 ? parseInt((item.from_feedback ? item.from_feedback.sum : 0) * 100 / item.isp.total.sum) + '%' : '0%')+") "),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isSalesOKK(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.sales_okk))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Продажи с обзвона качества минимум 10% за месяц ")])]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_c('OrdersSum',{class:{'mt-auto': _vm.salaryBonusesEnabled},attrs:{"sum":item.soc_networks ? item.soc_networks.sum : 0,"count":item.soc_networks ? item.soc_networks.count : 0,"with-av":true}}),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusSocialNetworks(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.soc_networks_okk))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Социальные сети: "),_c('ul',[_c('li',[_vm._v("Оформленных заказов > "+_vm._s(_vm.$root.printCost('750000')))]),_c('li',[_vm._v("Средний чек > "+_vm._s(_vm.$root.printCost('5000')))]),_c('li',[_vm._v("Удаленных заказов не более 40%")])])])]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_c('div',{class:{'mt-auto': _vm.salaryBonusesEnabled}},[_vm._v(" "+_vm._s(item.manager.conflict_orders.length)+" шт. ")]),_c('v-row',[_vm._v(" Бонусы и переуборки: "+_vm._s(_vm.calcConflictBonuses(item.manager.conflict_orders)[0])+" ("+_vm._s(_vm.calcConflictBonuses(item.manager.conflict_orders)[1])+"%) ")]),_c('v-row',[_vm._v(" Решено деньгами: "+_vm._s(_vm.calcConflictMoney(item.manager.conflict_orders)[0])+" ("+_vm._s(_vm.calcConflictMoney(item.manager.conflict_orders)[1])+"%) ")]),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isSatisfiedClients(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_satisfied_okk))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Более 80% негативных клиентов удовлетворено бонусами и скидками, менее 20% урегулировано деньгами ")])]):_vm._e(),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isResolveDuration(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.resolve_duration_okk))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Срок урегулирования конфликтных ситуаций до 7 дней ")])]):_vm._e()],1)]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.manager.late_sessions.length)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.manager.late_sessions.length)+" "+_vm._s(_vm.$root.numberWord(item.manager.late_sessions.length, ['смена', 'смены', 'смен']))+" ")]):_c('div',{staticStyle:{"color":"green"}},[_vm._v("нет")])])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_c('ul',[_c('li',[_vm._v("Опоздание более 2-х раз (более 10 минут) = -"+_vm._s(_vm.$root.printCost('5000')))]),_c('li',[_vm._v("Опоздание более 3-х раз (более 10 минут) = увольнение")])])])]),_c('ul',_vm._l((item.manager.late_sessions),function(session,i){return _c('li',{key:i,staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$moment(session.from.date).format('DD MMM'))+" "+_vm._s(session.from.time)+" ")])}),0)],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length)+" "+_vm._s(_vm.$root.numberWord(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length, ['смена', 'смены', 'смен']))+" ")]):_c('div',{staticStyle:{"color":"green"}},[_vm._v("нет")])])]}}],null,true)},[_c('div',[_vm._v(" 5 смен, где >10% пропущенных вызовов = -"+_vm._s(_vm.$root.printCost('2000'))+" ")])]),_c('ul',_vm._l((item.manager.missed_calls.filter(function (_call){ return (_call.missed * 100) / _call.total >=10; })),function(call,i){return _c('li',{key:i,staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$moment(call.date).format('DD MMM'))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(call.missed))]),_vm._v(" / "+_vm._s(call.total)+" ")])}),0)],1),_c('td',[(_vm.salaryBonusesEnabled)?[_vm._v(_vm._s(_vm.$root.printCost(_vm.calcManagerSalary(item))))]:[_vm._v("---")]],2)]):_c('tr',[_c('td',{staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_c('div',{class:{'mt-auto': _vm.salaryBonusesEnabled}},[_vm._v(_vm._s(item.manager.login))]),(item.manager.rate)?_c('Rate',{attrs:{"small":"","value":item.manager.rate}}):_vm._e(),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusRate(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_rate))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Рейтинг от клиентов > "+_vm._s(_vm.bonusInfo.rate)+" ")])]):_vm._e()],1)]),_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openManager(item.manager.n_id)}}},[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_vm._v(" Оформлено: "),_c('OrdersSum',{attrs:{"sum":item.total.sum,"count":item.total.count,"with-av":true}}),(item.isp)?[_vm._v(" Исполняется: "),_c('OrdersSum',{attrs:{"sum":item.isp.total.sum,"count":item.isp.total.count,"with-av":true}})]:_vm._e(),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusAvgCheck(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_average_bill))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Самый высокий средний чек за месяц среди всех менеджеров (> "+_vm._s(_vm.$root.printCost(_vm.bonusInfo.avrgBill))+") ")])]):_vm._e()],2)]),_c('td',[(item.isp)?_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_c('v-row',{class:{'mt-auto': _vm.salaryBonusesEnabled}},[_c('v-col',[_c('span',{staticClass:"d-inline-block cleantype-span"},[_vm._v("Рег.")])]),_c('v-col',[(item.isp.standart)?_c('OrdersSum',{attrs:{"sum":item.isp.standart.sum,"count":item.isp.standart.count,"with-av":true}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('span',{staticClass:"d-inline-block cleantype-span"},[_vm._v("Ген.")])]),_c('v-col',[(item.isp.general)?_c('OrdersSum',{attrs:{"sum":item.isp.general.sum,"count":item.isp.general.count,"with-av":true}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('span',{staticClass:"d-inline-block cleantype-span"},[_vm._v("Ремонт")])]),_c('v-col',[(item.isp.remont)?_c('OrdersSum',{attrs:{"sum":item.isp.remont.sum,"count":item.isp.remont.count,"with-av":true}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('span',{staticClass:"d-inline-block cleantype-span"},[_vm._v("Другое")])]),_c('v-col',[(item.isp.other)?_c('OrdersSum',{attrs:{"sum":item.isp.other.sum,"count":item.isp.other.count,"with-av":true}}):_vm._e()],1)],1),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusAvgCheckCleanings(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_average_bill_cleanings))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Средний чек не ниже: "),_c('ul',[_c('li',[_vm._v("быстрая - "+_vm._s(_vm.$root.printCost(_vm.bonusInfo.avgBillStandart)))]),_c('li',[_vm._v("генеральная - "+_vm._s(_vm.$root.printCost(_vm.bonusInfo.avgBillGeneral)))]),_c('li',[_vm._v("После ремонта - "+_vm._s(_vm.$root.printCost(_vm.bonusInfo.avgBillRemont)))])])])]):_vm._e()],1):_vm._e()]),_c('td',[_c('div',{staticClass:"flex-column d-flex justify-center",staticStyle:{"height":"100%"}},[_c('OrdersSum',{class:{'mt-auto': _vm.salaryBonusesEnabled},staticStyle:{"color":"red"},attrs:{"sum":item.canceled.sum,"count":item.canceled.count}}),_c('span',{staticClass:"cleantype-span d-block"},[_vm._v(" "+_vm._s(parseInt(100 * item.canceled.sum / (item.total.sum + item.canceled.sum)))+"% от всех ")]),(_vm.salaryBonusesEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isBonusDeletedOrders(item))?_c('div',_vm._g(_vm._b({staticClass:"salary-bonus-value mt-auto pb-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("+ " + (_vm.$root.printCost(_vm.salaryData.bonus_deleted_orders))))+" ")]):_c('div',_vm._g(_vm._b({staticClass:"salary-no-bonus mt-auto pb-4"},'div',attrs,false),on),[_vm._v("Без бонуса")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" Удаленных заказов не больше "+_vm._s(_vm.bonusInfo.deleted_orders)+"% ")])]):_vm._e()],1)]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.manager.late_sessions.length)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.manager.late_sessions.length)+" "+_vm._s(_vm.$root.numberWord(item.manager.late_sessions.length, ['смена', 'смены', 'смен']))+" ")]):_c('div',{staticStyle:{"color":"green"}},[_vm._v("нет")])])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"200px"}},[_c('ul',[_c('li',[_vm._v("Опоздание более 2-х раз (более 10 минут) = -"+_vm._s(_vm.$root.printCost('5000')))]),_c('li',[_vm._v("Опоздание более 3-х раз (более 10 минут) = увольнение")])])])]),_c('ul',_vm._l((item.manager.late_sessions),function(session,i){return _c('li',{key:i,staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$moment(session.from.date).format('DD MMM'))+" "+_vm._s(session.from.time)+" ")])}),0)],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length)+" "+_vm._s(_vm.$root.numberWord(item.manager.missed_calls.filter(function (call) { return (call.missed * 100) / call.total >= 10; }).length, ['смена', 'смены', 'смен']))+" ")]):_c('div',{staticStyle:{"color":"green"}},[_vm._v("нет")])])]}}],null,true)},[_c('div',[_vm._v(" 5 смен, где >10% пропущенных вызовов = -"+_vm._s(_vm.$root.printCost('2000'))+" ")])]),_c('ul',_vm._l((item.manager.missed_calls.filter(function (_call){ return (_call.missed * 100) / _call.total >=10; })),function(call,i){return _c('li',{key:i,staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$moment(call.date).format('DD MMM'))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(call.missed))]),_vm._v(" / "+_vm._s(call.total)+" ")])}),0)],1),_c('td',[(_vm.salaryBonusesEnabled)?[_vm._v(_vm._s(_vm.$root.printCost(_vm.calcManagerSalary(item))))]:[_vm._v("---")]],2)])]}}])})],1)],1)],1),_c('User',{ref:"manager_modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }